import {addChain, deleteChain, getChains, updateChain} from "../../../../redux/actions/chainsActions";
import {GetChannels} from "../../../../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import {useContext} from "react";
import {ToastContext} from "../../../Toast/ToastContext";
import {useTranslation} from "react-i18next";

export default (props) => {
	const dispatch = useDispatch();
	const {setToastInfo} = useContext(ToastContext);
	const {t} = useTranslation();
	const chainsAll = useSelector(state => state.chains?.chainsList || []);
	const {closeAndClearInput, chainName, channel, editChainInfo} = props;

	const DeleteChain = async (id) => {
		const findChain = chainsAll?.find((item) => item?.id === id);
		try {
			await dispatch(deleteChain(id, {
				name: findChain?.name,
				channel_id: channel.id,
				description: findChain?.description
			}));
			await dispatch(GetChannels());
			await dispatch(getChains());
			setToastInfo({
				isShow: true,
				type: 'success',
				text: t("delete_chain_success_text")
			});
		} catch (e) {
			setToastInfo({
				isShow: true,
				type: 'danger',
				text: t("delete_chain_error_text")
			});
			console.log(e)
		}
	}

	const AddChain = async (channel) => {
		try {
			await dispatch(addChain({
				name: chainName,
				channel_id: channel.id,
				description: ""
			}));
			await dispatch(GetChannels());
			await dispatch(getChains());
			closeAndClearInput();
			setToastInfo({
				isShow: true,
				type: 'success',
				text: t("add_chain_success_text")
			});
		} catch (e) {
			setToastInfo({
				isShow: true,
				type: 'danger',
				text: t("add_chain_error_text")
			});
			console.log(e)
		}
	}


	const UpdateChain = async () => {
		try {
			await dispatch(updateChain(editChainInfo?.id, {
				name: chainName,
				channel_id: channel.id,
				description: editChainInfo?.description
			}));
			await dispatch(GetChannels());
			await dispatch(getChains());

			closeAndClearInput();
			setToastInfo({
				isShow: true,
				type: 'success',
				text: t("update_chain_success_text")
			});

		} catch (e) {
			setToastInfo({
				isShow: true,
				type: 'danger',
				text: t("update_chain_error_text")
			});
			console.log(e)
		}
	}

	return {
		AddChain,
		DeleteChain,
		UpdateChain
	}
}