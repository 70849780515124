import React, {useEffect, useState} from 'react';
import './SortedItemsIcon.scss';
import {SortIcon, SortUpIcon} from "../../../../image";
import {VIDEOS_CONSTS} from "../../../../redux/constants";
import {ApiStatus} from "../../../../utils/constants";
import {GetVideosService, UpdateSortedParamsAction} from "../../../../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import {FILTER_SORT_PAGE_TYPES, getValueFilters} from "../../../Filters/helpers";
import {getSortedItems} from "../../helpers";

//pageType - FILTER_SORT_PAGE_TYPES
//itemSortType - SORTED_ITEM_TYPES
const SortedItemsIcon = (props) => {
	const {pageType,itemSortType} = props;
	const dispatch = useDispatch();
	const allFilters = useSelector(state => state.filtersOrSort);
	const allFiltersValue = getValueFilters(allFilters, FILTER_SORT_PAGE_TYPES.MANAGED_VIDEOS);
	const videosList = useSelector(state => state.videos?.videosList);
	const [count, setCount] = useState(0);
	const sortedValuesArr = getSortedItems(pageType,itemSortType);
	const sortItemValueState = useSelector(state => state.filtersOrSort?.[pageType].sort)

	const handleClickIcon = async () => {
		const newCount =  count + 1; // Выходим за границы, что в массиве не шел элемента
		const sortValue = sortedValuesArr[newCount];
		setCount(newCount);
		dispatch(UpdateSortedParamsAction({
			pageType,
			itemSortType,
			itemValue: sortValue
		}))
		try {
			let params = {
				...allFiltersValue,
				limit: videosList?.limit,
				offset: videosList?.offset,
				sort_by: sortValue
			}
			if(!sortValue){
				delete params.sort_by;
			}
			if(!videosList?.offset){
				delete params.offset;
			}
			dispatch({
				type: VIDEOS_CONSTS.CHANGE_API_STATUS_GET_VIDEOS,
				payload: ApiStatus.LOADING
			})
			await dispatch(GetVideosService(params));
			dispatch({
				type: VIDEOS_CONSTS.CHANGE_API_STATUS_GET_VIDEOS,
				payload: ApiStatus.SUCCESS
			})
		} catch (error) {
			console.log(error)
			dispatch({
				type: VIDEOS_CONSTS.CHANGE_API_STATUS_GET_VIDEOS,
				payload: ApiStatus.ERROR
			})
		}
	}

	useEffect(() => {
		const findIndex =  sortedValuesArr.findIndex((item) => item === sortItemValueState?.[itemSortType]);
		setCount(findIndex)
	}, []);

	return (
		<div className="sorted-items-icon" onClick={handleClickIcon}>
			{count >= 0 ?
			<SortUpIcon
				className={`${count === 1 ? 'active' :'' } ${count !== -1 ? 'selected' : ''}`}
			/> :
			<SortIcon/>
			}
		</div>
	);
};

export default SortedItemsIcon;