export const profileErrorMessages = {
    first_name: 'Имя должно содержать не менее 2 символов.',
    last_name: 'Фамилия должна содержать не менее 2 символов.',
    email: 'Эл. почта неверна',
    linkedin: 'Linkedin некорректный',
    facebook: 'Facebook некорректный',
    phone_number: 'Должно быть не менее 11 символов.',
    password: 'Ваш пароль должен быть длиной не менее 8 символов',
    login: 'Пользователь уже связан с человеком',
    required: 'Обязательное поле',

    // first_name: 'Name must be at least 2 characters',
    // last_name: 'Surname must be at least 2 characters',
    // email: 'E-mail is incorrect',
    // linkedin: 'Linkedin is incorrect',
    // facebook: 'Facebook is incorrect',
    // phone_number: 'Must be at least 11 characters',
    // password: 'Your password must be at least 8 characters long',
    // login: 'The user is already associated with the person',
}


export const MIN_ZOOM = 0;
export const MAX_ZOOM = 1.5;

export const HOUR_IN_SECOND = 3600;
// export const MIN_MENU_SIZE = 100;
// export const MAX_MENU_SIZE = 233;

export const ROLE_ITEMS = {
    SELF_PROFILE_PARTICIPATION_EDIT:'self_profile_participation_edit',
    SELF_PROFILE_USER_SETTINGS_EDIT:'self_profile_user_settings_edit',
    SELF_PROFILE_CALENDAR_EDIT:'self_profile_calendar_edit',
    SELF_PROFILE_DELETE:'self_profile_delete',

    FOREIGN_PARTICIPANTS_DELETE:'foreign_participants_delete',
    FOREIGN_PARTICIPANTS_PROFILE_PERSONAL_EDIT:'foreign_participants_profile_personal_edit',
    FOREIGN_PARTICIPANTS_PROFILE_PARTICIPATION_EDIT:'foreign_participants_profile_participation_edit',

    FOREIGN_USERS_CREATE:'foreign_users_create',
    FOREIGN_USERS_PROFILE_PERSONAL_EDIT:'foreign_users_profile_personal_edit',
    FOREIGN_USERS_PROFILE_USER_SETTINGS_READ:'foreign_users_profile_user_settings_read',
    FOREIGN_USERS_PROFILE_USER_SETTINGS_EDIT:'foreign_users_profile_user_settings_edit',
    FOREIGN_USERS_PROFILE_PARTICIPATION_EDIT: "foreign_users_profile_participation_edit",
    FOREIGN_USERS_DELETE:'foreign_users_delete',

    FOREIGN_VIDEOS_FULL_ACCESS:'foreign_videos_full_access',
    FOREIGN_VIDEOS_MAIN_PROPERTIES_EDIT:'foreign_videos_main_properties_edit',
    FOREIGN_VIDEOS_AI_TEXT_EDIT:'foreign_videos_ai_text_edit',
    FOREIGN_VIDEO_FAVORITES_EDIT:'foreign_video_favorites_edit',
    FOREIGN_VIDEO_SHARING_EDIT:'foreign_video_sharing_edit',
    FOREIGN_VIDEO_CHAINS_FULL_ACCESS:'foreign_video_chains_full_access',

    SELF_VIDEOS_SHARE_LINKS_EDIT:"self_videos_share_links_edit",
    SELF_VIDEOS_DELETE:"self_videos_delete",

    TAGS_EDIT:"tags_edit",
    TAGS_DELETE:"tags_delete",

    CHANNELS_CREATE:"channels_create",
    CHANNELS_EDIT:"channels_edit",
    CHANNELS_DELETE:"channels_delete",

    ROLES_CREATE:"roles_create",
    ROLES_EDIT:"roles_edit",
    ROLES_DELETE:"roles_delete",
    ROLES_READ:'roles_read',

}
export const rolePermissions = (lng) => {
    return {
        [lng === 'ru' ? 'Личный профиль' : 'Personal Profile'] : [ROLE_ITEMS.SELF_PROFILE_PARTICIPATION_EDIT, ROLE_ITEMS.SELF_PROFILE_USER_SETTINGS_EDIT,ROLE_ITEMS.SELF_PROFILE_CALENDAR_EDIT,ROLE_ITEMS.SELF_PROFILE_DELETE],
        [lng === 'ru' ? 'Профили без доступа к системе': 'Profiles without system access'] : [ROLE_ITEMS.FOREIGN_PARTICIPANTS_PROFILE_PERSONAL_EDIT,ROLE_ITEMS.FOREIGN_PARTICIPANTS_PROFILE_PARTICIPATION_EDIT, ROLE_ITEMS.FOREIGN_PARTICIPANTS_DELETE],
        [lng === 'ru' ? 'Чужие профили с доступом в систему' : 'Profiles With System Access'] : [
            ROLE_ITEMS.FOREIGN_USERS_CREATE,
            ROLE_ITEMS.FOREIGN_USERS_PROFILE_PERSONAL_EDIT,
            ROLE_ITEMS.FOREIGN_USERS_PROFILE_USER_SETTINGS_EDIT,
            ROLE_ITEMS.FOREIGN_USERS_PROFILE_USER_SETTINGS_READ,
            ROLE_ITEMS.FOREIGN_USERS_PROFILE_PARTICIPATION_EDIT,
            ROLE_ITEMS.FOREIGN_USERS_DELETE
        ],
        [lng === 'ru' ? 'Чужие видео' : 'Non-Personal Videos'] : [
          ROLE_ITEMS.FOREIGN_VIDEOS_FULL_ACCESS,
          ROLE_ITEMS.FOREIGN_VIDEOS_MAIN_PROPERTIES_EDIT,
          ROLE_ITEMS.FOREIGN_VIDEOS_AI_TEXT_EDIT,
          ROLE_ITEMS.FOREIGN_VIDEO_FAVORITES_EDIT,
          ROLE_ITEMS.FOREIGN_VIDEO_SHARING_EDIT,
          ROLE_ITEMS.FOREIGN_VIDEO_CHAINS_FULL_ACCESS,
        ],
        [lng === 'ru' ? 'Собственные видео' : 'Personal Videos'] : [ROLE_ITEMS.SELF_VIDEOS_SHARE_LINKS_EDIT, ROLE_ITEMS.SELF_VIDEOS_DELETE],
        [lng === 'ru' ? 'Тэги' : 'Tags'] : [ROLE_ITEMS.TAGS_EDIT, ROLE_ITEMS.TAGS_DELETE],
        [lng === 'ru' ? 'Общие каналы' : 'Public Channels'] : [ROLE_ITEMS.CHANNELS_CREATE, ROLE_ITEMS.CHANNELS_EDIT, ROLE_ITEMS.CHANNELS_DELETE],
        [lng === 'ru' ? 'Конструктор ролей' : 'Role builder'] : [ROLE_ITEMS.ROLES_CREATE, ROLE_ITEMS.ROLES_READ, ROLE_ITEMS.ROLES_EDIT, ROLE_ITEMS.ROLES_DELETE],
    }
}

export const rolesName = (lng) =>{
    return {
        [ROLE_ITEMS.SELF_PROFILE_PARTICIPATION_EDIT]: lng === 'ru' ? 'Редактирование блока участия в видео' : 'Edit video participation block',
        [ROLE_ITEMS.SELF_PROFILE_USER_SETTINGS_EDIT]: lng === 'ru' ? 'Редактирование блока пользовательских полей' : 'Edit user fields block',
        [ROLE_ITEMS.SELF_PROFILE_CALENDAR_EDIT]: lng === 'ru' ? 'Редактирование календаря' : 'Edit calendar',
        [ROLE_ITEMS.SELF_PROFILE_DELETE]: lng === 'ru' ? 'Удаление профиля' : 'Delete profile',

        [ROLE_ITEMS.FOREIGN_PARTICIPANTS_PROFILE_PERSONAL_EDIT]: lng === 'ru'? 'Редактирование блока личной информации' : 'Edit personal information block',
        [ROLE_ITEMS.FOREIGN_PARTICIPANTS_PROFILE_PARTICIPATION_EDIT]: lng === 'ru'? 'Редактирование блока участия в видео' : 'Edit video participation block',
        [ROLE_ITEMS.FOREIGN_PARTICIPANTS_DELETE]: lng === 'ru'? 'Удаление профиля' : 'Delete profile',

        [ROLE_ITEMS.FOREIGN_USERS_CREATE]: lng === 'ru'? 'Создание профиля' : 'Create profile',
        [ROLE_ITEMS.FOREIGN_USERS_PROFILE_PERSONAL_EDIT]: lng === 'ru'? 'Редактирование блока личной информации' : 'Edit personal information block',
        [ROLE_ITEMS.FOREIGN_USERS_PROFILE_USER_SETTINGS_READ]: lng === 'ru'? 'Просмотр блока пользовательских полей' : 'View user fields block',
        [ROLE_ITEMS.FOREIGN_USERS_PROFILE_USER_SETTINGS_EDIT]: lng === 'ru'? 'Редактирование блока пользовательских полей' : 'Edit user fields block',
        [ROLE_ITEMS.FOREIGN_USERS_PROFILE_PARTICIPATION_EDIT]: lng === 'ru'? 'Редактирование блока участие в видео' : 'Edit video participation block',
        [ROLE_ITEMS.FOREIGN_USERS_DELETE]: lng === 'ru'? 'Удаление профиля' : 'Delete profile',

        [ROLE_ITEMS.FOREIGN_VIDEOS_FULL_ACCESS]: lng === 'ru'? 'Полный доступ ко всем видео в системе' : 'Full access to all videos in the system',
        [ROLE_ITEMS.FOREIGN_VIDEOS_MAIN_PROPERTIES_EDIT]: lng === 'ru'? 'Редактирование основных полей у доступных видео' : 'Edit main fields for accessible videos',
        [ROLE_ITEMS.FOREIGN_VIDEOS_AI_TEXT_EDIT]: lng === 'ru'? 'Редактирование AI-панель у доступных видео' : 'Edit AI panel for accessible videos',
        [ROLE_ITEMS.FOREIGN_VIDEO_FAVORITES_EDIT]: lng === 'ru'? 'Редактирование Favorites у доступных видео' : 'Edit Favorites for accessible videos',
        [ROLE_ITEMS.FOREIGN_VIDEO_SHARING_EDIT]: lng === 'ru'? 'Шеринг у доступных видео' : 'Share accessible videos',
        [ROLE_ITEMS.FOREIGN_VIDEO_CHAINS_FULL_ACCESS]: lng === 'ru'? 'Группировка в цепочки в доступных каналах' : 'Group into chains in accessible channels',

        [ROLE_ITEMS.SELF_VIDEOS_SHARE_LINKS_EDIT]: lng === 'ru'? 'Шеринг' : 'Share',
        [ROLE_ITEMS.SELF_VIDEOS_DELETE]: lng === 'ru'? 'Удаление' : 'Delete',

        [ROLE_ITEMS.TAGS_EDIT]: lng === 'ru'? 'Редактирование' : 'Edit',
        [ROLE_ITEMS.TAGS_DELETE]: lng === 'ru'? 'Удаление' : 'Delete',

        [ROLE_ITEMS.CHANNELS_CREATE]: lng === 'ru'? 'Создание' : 'Create',
        [ROLE_ITEMS.CHANNELS_EDIT]: lng === 'ru'? 'Просмотр и редактировние' : 'View and edit',
        [ROLE_ITEMS.CHANNELS_DELETE]: lng === 'ru'? 'Удаение' : 'Delete',

        [ROLE_ITEMS.ROLES_CREATE]: lng === 'ru'? 'Создание' : 'Create',
        [ROLE_ITEMS.ROLES_READ]: lng === 'ru'? 'Просмотр' : 'View',
        [ROLE_ITEMS.ROLES_EDIT]: lng === 'ru'? 'Редактирование' : 'Edit',
        [ROLE_ITEMS.ROLES_DELETE]: lng === 'ru'? 'Удаление' : 'Delete'
    }
}

export const roleTooltipText = (lng) =>{
    return {
        [ROLE_ITEMS.SELF_PROFILE_PARTICIPATION_EDIT]: lng === 'ru' ?
          `* отредактируйте свою подпись под видео-конференцией\n* объедините свой профиль с другими участниками\n* отключите ссылку на свой профиль из видео` :
          `* edit your video conference signature\n* merge your profile with other participants\n * unlink your profile from videos`
        ,
        [ROLE_ITEMS.SELF_PROFILE_USER_SETTINGS_EDIT]: lng === 'ru' ?
          'Измените логин и пароль в своем собственном профиле'
          : 'Change the login and password in your own profile',

        [ROLE_ITEMS.FOREIGN_PARTICIPANTS_PROFILE_PERSONAL_EDIT]: lng === 'ru'
          ? 'Измените имя, фамилию, компанию, должность, заметки, теги, контакты для участников, не связанных с пользователями вашей компании'
          : 'Edit name, surname, company, position, notes, tags, contacts for participants not associated with users in your company',
        [ROLE_ITEMS.FOREIGN_PARTICIPANTS_PROFILE_PARTICIPATION_EDIT]: lng === 'ru'
          ? '* отредактируйте подпись для видеоконференции\n* объедините профили двух участников, которые не связаны с пользователями \n* отсоедините профили участников, не связанных с пользователями, от видеозаписей, к которым у вас есть доступ'
          : '* edit the video conference signature\n* merge the profiles of two participants who are not linked to users\n* unlink the profiles of participants not linked to users from videos you have access to',

        [ROLE_ITEMS.FOREIGN_USERS_PROFILE_PERSONAL_EDIT]: lng === 'ru'
          ? 'Редактируйте имя, фамилию, компанию, должность, заметки, теги, контакты для всех пользователей и связанных с ними участников'
          : 'Edit name, surname, company, position, notes, tags, contacts for all users and their associated participants',
        [ROLE_ITEMS.FOREIGN_USERS_PROFILE_USER_SETTINGS_READ]: lng === 'ru'
          ? 'Просмотр ролей и логинов зарегистрированных пользователей'
          : 'View the role and login of registered users',
        [ROLE_ITEMS.FOREIGN_USERS_PROFILE_USER_SETTINGS_EDIT]: lng === 'ru'
          ? 'Просматривайте и меняйте имя пользователя, меняйте пароль и изменяйте роли для существующих пользователей, а также назначайте роли при создании новых пользователей'
          : 'View and change the username, change the password, and modify the role for existing users, as well as assign a role when creating new users',
        [ROLE_ITEMS.FOREIGN_USERS_PROFILE_PARTICIPATION_EDIT]: lng === 'ru'
          ? '* отредактируйте подпись к видео-конференции\n* объедините профиль с другими участниками\n* отключите привязку профиля к видео'
          : '* edit the video conference signature\n* merge the profile with other participants\n* unlink the profile from videos',


        [ROLE_ITEMS.FOREIGN_VIDEOS_FULL_ACCESS]: lng === 'ru'
         ? '* просматривайте, редактируйте, удаляйте видео и управляйте ими\n* редактируйте AI-заметки, транскрипцию, избранное\n* меняйте титры, настройки общего доступа\n *управляйте каналами и цепочками'
         : '* view, edit, delete, and manage video\n* modifying AI-notes, Transcribe, Favorites\n* change titles, sharing settings\n *manage channels and chains',
        [ROLE_ITEMS.FOREIGN_VIDEOS_MAIN_PROPERTIES_EDIT]: lng === 'ru'
          ? 'Измените название и канал для всех доступных видеороликов'
          : 'Edit the title and channel for all accessible videos',
        [ROLE_ITEMS.FOREIGN_VIDEOS_AI_TEXT_EDIT]: lng === 'ru'
          ? 'Редактируйте все транскрипции, соглашения, аналитические материалы, резюме для всех доступных видеороликов\''
          : 'Edit all transcripts, agreements, insights, recaps for all accessible videos',
        [ROLE_ITEMS.FOREIGN_VIDEO_FAVORITES_EDIT]: lng === 'ru'
          ? 'Отредактируйте все избранное в личных профилях и избранное для всех доступных видео'
          : 'Edit all favorites in personal profiles and favorites for all accessible videos',
        [ROLE_ITEMS.FOREIGN_VIDEO_SHARING_EDIT]: lng === 'ru'
          ? 'Измените тип ссылки (общедоступная/непубличная) для всех доступных видеороликов'
          : 'Edit the link type (public/non-public) for all accessible videos',
        [ROLE_ITEMS.FOREIGN_VIDEO_CHAINS_FULL_ACCESS]: lng === 'ru'
          ? '* назначайте цепочки для доступных видеороликов\n* создавайте, удаляйте и редактируйте цепочки в этих каналах'
          : '* assign chains to accessible videos\n* create, delete, and edit chains in these channels',

        [ROLE_ITEMS.SELF_VIDEOS_SHARE_LINKS_EDIT]: lng === 'ru'
          ? 'Измените тип ссылки для обмена вашими собственными видео (общедоступные /непубличные).'
          : 'Change the sharing link type for your own videos (public/non-public)',


        [ROLE_ITEMS.CHANNELS_EDIT]: lng === 'ru'
          ? 'Человек с такой настройкой становится менеджером всех каналов в компании'
          : 'The person with this setting becomes the manager of all channels in the company',
    }
}

export const rolePermissionsTooltip = (lng) => {
    return {
        [lng === 'ru' ? 'Профили без доступа к системе': 'Profiles without system access'] :
          lng === 'ru'
            ? 'Лицо, зарегистрированное в системе в качестве пользователя'
            : 'A person registered in the system as a user',

        [lng === 'ru' ? 'Чужие профили с доступом в систему' : 'Profiles With System Access'] :
          lng === 'ru'
            ? 'Участник видеосъемки, который не связан ни с одним пользователем системы'
            : 'A video participant who is not associated with any system user',
        [lng === 'ru' ? 'Чужие видео' : 'Non-Personal Videos'] :
          lng === 'ru'
              ? 'Видео, владельцем которых пользователь не является'
              : 'Videos where the user is not the owner',
        [lng === 'ru' ? 'Собственные видео' : 'Personal Videos'] :
          lng === 'ru'
            ? 'Видеоролики, владельцем которых является пользователь'
            : 'Videos where the user is the owner',

    }
}

export const uris = {
    OAUTH2_PROVIDER: "https://demo1-reis.dev.ntrnx.com",
    LOGIN_REDIRECT: "/dashboard/videos/review",
    LOGOUT_REDIRECT: "/",
}

export const statusesList = {
    uploading: {
        key: 'uploading',
        name: 'Up.'
    },
    converting: {
        key: 'converting',
        name: 'Cv.'
    },
    'conversion-failed': {
        key: 'conversion-failed',
        name: 'Cv-F.'
    },
    processing: {
        key: 'processing',
        name: 'Pr.'
    },
    'processing-rejected': {
        key: 'processing-rejected',
        name: 'Pr-R.'
    },
    'processing-failed': {
        key: 'processing-failed',
        name: 'Pr-F.'
    },
    processed: {
        key: 'processed',
        name: 'Prd.'
    },
    'sending-to-storage': {
        key: 'sending-to-storage',
        name: 'S-S.'
    }
}

export const statusesListRu = {
    uploading: {
        key: 'загрузка',
        name: 'Загр.'
    },
    converting: {
        key: 'конвертирование',
        name: 'Конв.'
    },
    'conversion-failed': {
        key: 'конвертирование не выполнено',
        name: 'Конв. н.в.'
    },
    processing: {
        key: 'обработка',
        name: 'Обр-ка'
    },
    'processing-rejected': {
        key: 'обработка отклонена',
        name: 'Обр. откл.'
    },
    'processing-failed': {
        key: 'обработка не выполнена',
        name: 'Обр. н.в.'
    },
    processed: {
        key: 'обработано',
        name: 'Обр-но'
    },
    'sending-to-storage': {
        key: 'отправка в хранилище',
        name: 'Отпр. в хр.'
    }
}

export  const monthsShort = [
    "Янв.", "Февр.", "Март", "Апр.", "Май", "Июнь",
    "Июль", "Авг.", "Сент.", "Окт.", "Нояб.", "Дек."
];

export  const monthsShortEn = [
    "Jan.", "Feb.", "March", "Apr.", "May", "June",
    "July", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."
];

export const ApiStatus =  {
    INITIAL: 'INITIAL',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    LOADING: 'LOADING',
}