import React from 'react';
import './FiltersList.scss';
import {useDispatch, useSelector} from "react-redux";
import {ALL_FILTERS, FILTER_SORT_PAGE_TYPES, getValueFilters} from "../../helpers";
import FilterChannels from "../FilterChannels/FilterChannels";
import FilterLinks from "../FilterLinks/FilterLinks";
import FilterStatusVideo from "../FilterStatusVideo/FilterStatusVideo";
import FiltersAll from "../FiltersAll/FiltersAll";
import {CloseStrokeIcon} from "../../../../image";
import {Button} from "../../../Button";
import {useTranslation} from "react-i18next";
import {VIDEOS_CONSTS} from "../../../../redux/constants";
import {ApiStatus} from "../../../../utils/constants";
import {GetVideosService,ResetFiltersAction} from "../../../../redux/actions";
import FilterName from "../FilterName/FilterName";
import FilterDate from "../FilterDate/FilterDate";
import FilterParticipants from "../FilterParticipants/FilterParticipants";
import {useParams} from "react-router-dom";
import FilterChains from "../FilterChains/FilterChains";
import SortedSelect from "../../../Sorted/components/SortedSelect/SortedSelect";

const FiltersList = (props) => {
	const {pageType} = props;
	const filters = useSelector(state => state.filtersOrSort?.[pageType].filters?.selectedFilters || []);
	const {t} = useTranslation();
	const urlParams = useParams();
	const channels = useSelector(state => state.channels?.channels || []);

	const dispatch = useDispatch();
	const resetFilters = async (params) => {
		try {
			await dispatch(ResetFiltersAction({
				pageType
			}));
			await getVideos({},'reset')
		} catch (error) {
			console.log(error)
		}
	}
	const getParams = (data, type) => {
		let params = {
			...data,
			limit: 20,
		}

		if(pageType === FILTER_SORT_PAGE_TYPES.USER_VIDEOS_PAGE){
			if(type === 'reset'){
				return {
					limit: 1000,
					person_id: urlParams?.id,
					sort_by: 'creation_time_desc'
				}
			}
			let newParams ={
				...params,
				limit: 1000,
				person_id: urlParams?.id
			}
			if(newParams.person_ids){
				newParams.person_ids = [...newParams.person_ids, urlParams?.id]
				delete newParams.person_id;
			}
			return newParams
		}
		if(pageType === FILTER_SORT_PAGE_TYPES.CHAIN_PAGE){
			if(type === 'reset'){
				return {
					limit: 1000,
					chain_id: urlParams?.chainId,
					channel_id: urlParams?.channelId,
					sort_by: 'creation_time_desc'
				}
			}
			return {
				...params,
				limit: 1000,
				chain_id: urlParams?.chainId,
				channel_id: urlParams?.channelId
			}
		}
		if(pageType === FILTER_SORT_PAGE_TYPES.CHANNEL_PAGE){
			if(type === 'reset'){
				return {
					limit: 1000,
					channel_id: urlParams?.channelId,
					sort_by: 'creation_time_desc'
				}
			}
			return {
				...params,
				limit: 1000,
				channel_id: urlParams?.channelId
			}
		}
		if(pageType === FILTER_SORT_PAGE_TYPES.KNOWLEDGE_DATA){
			if(type === 'reset'){
				return {
					limit: 1000,
					sort_by: 'creation_time_desc',
					channel_ids: channels.map((item) => item?.id)
				}
			}
			return {
				...params,
				limit: 1000,
			}
		}
		if(pageType === FILTER_SORT_PAGE_TYPES.MANAGED_VIDEOS){
			if(type === 'reset'){
				return {
					limit: 20,
					manage: true,
				}
			}
			return {
				manage: true,
				...params,
			}
		}
	}

	const getVideos = async (params,type) => {
		try {
			dispatch({
				type: VIDEOS_CONSTS.CHANGE_API_STATUS_GET_VIDEOS,
				payload: ApiStatus.LOADING
			})
			const response = await dispatch(GetVideosService({
				...getParams(params,type),
			}, [FILTER_SORT_PAGE_TYPES.CHANNEL_PAGE].includes(pageType)));
			if(FILTER_SORT_PAGE_TYPES.CHANNEL_PAGE === pageType){
				dispatch({
					type: VIDEOS_CONSTS.GET_VIDEOS_CHANNEL,
					payload: response
				})
			}
			dispatch({
				type: VIDEOS_CONSTS.CHANGE_API_STATUS_GET_VIDEOS,
				payload: ApiStatus.SUCCESS
			})
		} catch (error) {
			console.log(error)
			dispatch({
				type: VIDEOS_CONSTS.CHANGE_API_STATUS_GET_VIDEOS,
				payload: ApiStatus.ERROR
			})
		}
	}

	const FILTERS = [
		{
			id: 0,
			type: ALL_FILTERS.CHANNELS,
			component: <FilterChannels onGetVideos={getVideos} pageType={pageType}/>
		},
		{
			id: 1,
			type: ALL_FILTERS.LINK,
			component:<FilterLinks onGetVideos={getVideos} pageType={pageType}/>
		},
		{
			id: 2,
			type: ALL_FILTERS.STATUS,
			component: <FilterStatusVideo onGetVideos={getVideos} pageType={pageType}/>
		},
		{
			id: 3,
			type: ALL_FILTERS.NAME,
			component: <FilterName onGetVideos={getVideos} pageType={pageType}/>
		},
		{
			id: 4,
			type: ALL_FILTERS.DATE,
			component: <FilterDate onGetVideos={getVideos} pageType={pageType}/>
		},
		{
			id: 5,
			type: ALL_FILTERS.PARTICIPANTS,
			component: <FilterParticipants onGetVideos={getVideos} pageType={pageType}/>
		},
		{
			id: 6,
			type: ALL_FILTERS.CHAINS,
			component: <FilterChains onGetVideos={getVideos} pageType={pageType}/>
		},
	]

	return (
		<div className="filters-list">
			<div className="filters-list__items">
				{pageType !== FILTER_SORT_PAGE_TYPES.MANAGED_VIDEOS ? <SortedSelect pageType={pageType} onGetVideos={getVideos}/> : null}
				{filters.map((filter) => {
					const findItem = FILTERS.find((item) => item.type === filter);
					if(findItem){
						return <div key={findItem?.id}>{findItem.component}</div>
					}
					return null
				})}
				{filters?.length > 0
					? <FiltersAll pageType={pageType} btnType={'ghost'}/>
					: null
				}
			</div>
			{filters?.length > 0 ?
			<Button
				onClick={resetFilters}
				leftIcon={<CloseStrokeIcon/>}
				text={t('reset_all')}
				variant="ghost"
				size="xs"
			/>
				: null}
		</div>
	);
};

export default FiltersList;