// Import packages
import React, {useEffect, useState} from 'react';
import {
    NavLink, useLocation
} from 'react-router-dom';
import {connect, useSelector} from "react-redux";
import {Accordion, useAccordionButton} from "react-bootstrap";

import {
    AnonymousMenuIcon,
    BuildingsIcon,
    CatalogueMenuIcon, ListIcon, LockIcon,
    ManageMenuIcon,
    OpenMenuIcon, PersonIcon, RolesIcon, ShevronTopIcon, VideoIcon,
    VideoMenuIcon
} from "../../../image";
import './menu.scss';

// Import Components
import DropdownMenu from "../dropdownMenu";

// Import Utils
import {history} from "../../../utils/history";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {
    GetUserById,
    GetUserProfile,
    LogOut,
    SetIsFullWidth,
    GetPersonById,
    ClearProfile,
    SetUserAvatar, GetOutlookAuth
} from "../../../redux/actions";
import {generateMediaUrl} from "../../../utils/generateMediaUrl";
import {useTranslation} from "react-i18next";
import {getAuthorName, hasPermission} from "../../../utils/helperFunctions";
import config from "../../../utils/config";
import {Tooltip} from "antd";
import {ROUTERS_MAP} from "../../../utils/routes";
import {ROLE_ITEMS} from "../../../utils/constants";

function Menu({
                  LogOut,
                  GetUserProfile,
                  userProfile,
                  GetUserById,
                  userById,
                  isFullWidth,
                  SetIsFullWidth,
                  GetPersonById,
                  userRoles,
                  ClearProfile,
                  SetUserAvatar,
                  userAvatar,
                  GetOutlookAuth
              }) {
    const location = useLocation()
    const {t, i18n} = useTranslation();
    const channels = useSelector(state => state?.channels?.channels || []);
    const [activeTab, setActiveTab] = useState('0')
    const sortChannels = channels.sort((a) => {
        if (a?.is_private) return -1;
        return 1
    }).sort((a) => {
        if (a.name === 'Личное' || a?.name === 'Personal' || a?.name === "Private") return -1;
        return 1
    })

    const [hoverIcon, setHoverIcon] = useState(false);
    useEffect(() => {
        GetUserProfile().then((res) => {
            res?.attached_person?.id && GetPersonById(res?.attached_person?.id, true).then((res) => {
                SetUserAvatar(res?.avatar)
            })
        })
    }, []);
    useEffect(() => {
        // userProfile?.attached_person?.id && GetPersonById(userProfile?.attached_person?.id).then((res) => {
        //     setAvatar(res?.avatar)
        // });
        SetIsFullWidth(!!location?.pathname?.includes('review'))
    }, [location?.pathname])

    useEffect(() => {
        GetUserById(userProfile?.id);
        GetOutlookAuth();
    }, [userProfile?.id])

    const logOut = () => {
        LogOut()
            .then(() => {
                history.push('/');
            })
        ClearProfile()
    }

    function CustomToggle({children, eventKey}) {
        const decoratedOnClick = useAccordionButton(eventKey, () =>
            setActiveTab(eventKey === activeTab ? null : eventKey),
        );

        return (
            <ShevronTopIcon
                className={`menu_item--shevron ${activeTab === eventKey ? 'active' : ''} `}
                onClick={decoratedOnClick}
            />
        );
    }

    return (
        <div className={`menu ${!isFullWidth ? 'small' : ''}`}>
            <div className="">
                {isFullWidth ? <NavLink to="/dashboard/videos/review"
                                        className={`menu__logo ${!isFullWidth ? 'small-menu' : ''}`}
                                        exact>
                        {t('secretopus_name')}
                        {isFullWidth && <button className="open-menu" onClick={() => {
                            SetIsFullWidth(!isFullWidth)
                        }}>
                            <OpenMenuIcon/>
                        </button>}
                    </NavLink>
                    : <button className={`open-menu menu__logo ${!isFullWidth ? 'small-menu' : ''}`}
                              onMouseOver={() => setHoverIcon(true)}
                              onMouseLeave={() => setHoverIcon(false)}
                              onClick={() => {
                                  SetIsFullWidth(!isFullWidth)
                              }}>
                        {!hoverIcon ? <span className={''}>
                            {t('secretopus_name')?.slice(0, 1)}
                        </span> :
                            <Tooltip placement="right" title={t('menu_open')}>
                                <OpenMenuIcon/>
                            </Tooltip>
                        }
                    </button>}
                <Accordion defaultActiveKey={"0"}>
                    <Accordion.Item eventKey="0" className={`sub_menu ${!isFullWidth ? 'small-menu' : ''}`}>
                        <div
                            className={`menu_item ${location.pathname.includes('videos') ? 'active_menu' : ''} ${!isFullWidth ? 'small-menu' : ''}`}>
                            <NavLink
                                to={`/dashboard/videos/review`}
                                className="menu_link"
                                activeClassName="active_link"
                                exact>
                                {isFullWidth ? <>
                                        <VideoIcon/>
                                        {isFullWidth && <p>{t('menu_item_knowledge')}</p>}
                                    </> :
                                    <Tooltip placement="right" title={t('menu_item_knowledge')}>
                                        <VideoIcon/>
                                    </Tooltip>
                                }
                            </NavLink>
                            <CustomToggle eventKey="0"/>
                        </div>
                        <Accordion.Body className="channels custom-scroll">
                            {sortChannels.map((item) => (
                                <NavLink
                                    key={item?.id}
                                    to={{
                                        pathname: `${ROUTERS_MAP.CHANNEL}/${item.id}`,
                                        state: {
                                            routs: [
                                                {
                                                    path: ROUTERS_MAP.KNOWLEDGE_DATA,
                                                    name: t('menu_item_knowledge')
                                                }
                                            ]
                                        }
                                    }

                                    }
                                    className="menu_link menu__sub_item"
                                    activeClassName="active_link"
                                    exact
                                >
                                    {isFullWidth ?
                                        <Tooltip placement="right" title={item?.name}>
                                            <div className="menu__sub_item--wrap">
                                                {item?.is_private ?
                                                    <LockIcon
                                                        style={{marginLeft: '-4px'}}
                                                        className="menu__sub_item--wrap-icon"/> : null}
                                                <span>{item?.name}</span>
                                            </div>
                                        </Tooltip>
                                        :
                                        <Tooltip placement="right" title={item?.name}>
                                            <div className="menu__sub_item--wrap">
                                                {item?.is_private ? <LockIcon className="menu__sub_item--wrap-icon"/> :

                                                    <div className="menu__sub_item--wrap">
                                                        {item?.is_private ?
                                                            <LockIcon className="menu__sub_item--wrap-icon"/> :
                                                          <span className="menu__sub_item-dot">
                                                              {(item?.name || '')[0]}
                                                          </span>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </Tooltip>
                                    }

                                </NavLink>
                            ))}
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" className={`sub_menu ${!isFullWidth ? 'small-menu' : ''}`}>
                        <div
                            className={`menu_item ${location.pathname.includes('manage-data') ? 'active_menu' : ''} ${!isFullWidth ? 'small-menu' : ''}`}>
                            <NavLink
                                to={ROUTERS_MAP.MANAGE_DATA}
                                className="menu_link"
                                activeClassName="active_link"
                                exact>
                                {isFullWidth ? <>
                                        <ListIcon/>
                                        {isFullWidth && <p>{t('menu_item_manage_data')}</p>}
                                    </> :
                                    <Tooltip placement="right" title={t('menu_item_manage_data')}>
                                        <ListIcon/>
                                    </Tooltip>
                                }
                            </NavLink>
                            <CustomToggle eventKey="1"/>
                        </div>
                        <Accordion.Body>
                            <NavLink to={ROUTERS_MAP.MANAGE_DATA}
                                     className="menu_link menu__sub_item"
                                     activeClassName="active_link"
                                     exact>
                                {isFullWidth ? t('menu_item_artifacts')
                                    :
                                    <Tooltip placement="right" title={t('menu_item_artifacts')}>
                                        <ManageMenuIcon/>
                                    </Tooltip>
                                }
                            </NavLink>
                                <NavLink to={ROUTERS_MAP.CHANNELS}
                                    className="menu_link menu__sub_item"
                                    activeClassName="active_link"
                                    exact>
                                    {isFullWidth ? t('menu_item_channels') :
                                      <Tooltip placement="right" title={t('menu_item_channels')}>
                                          <VideoMenuIcon/>
                                      </Tooltip>
                                    }
                                </NavLink>
                        </Accordion.Body>
                    </Accordion.Item>
                    {<Accordion.Item eventKey="2" className={`sub_menu ${!isFullWidth ? 'small-menu' : ''}`}>
                        <div
                            className={`menu_item ${location.pathname.includes('users') ? 'active_menu' : ''} ${!isFullWidth ? 'small-menu' : ''}`}>
                            <NavLink
                                to={`/dashboard/users/persons`}
                                className="menu_link"
                                activeClassName="active_link"
                                exact>
                                {isFullWidth ? <>
                                        <PersonIcon/>
                                        {isFullWidth && <p>{t('menu_item_persons')}</p>}
                                    </> :
                                    <Tooltip placement="right" title={t('menu_item_persons')}>
                                        <PersonIcon/>
                                    </Tooltip>
                                }
                            </NavLink>
                            <CustomToggle eventKey="2"/>
                        </div>
                        <Accordion.Body>
                                <NavLink to={'/dashboard/users/persons'}
                                         className="menu_link menu__sub_item"
                                         activeClassName="active_link"
                                         exact>
                                    {isFullWidth ? t('menu_item_catalogue') :
                                        <Tooltip placement="right" title={t('menu_item_catalogue')}>
                                            <CatalogueMenuIcon/>
                                        </Tooltip>
                                    }
                                </NavLink>
                                <NavLink to={'/dashboard/users/new'}
                                         className="menu_link menu__sub_item"
                                         activeClassName="active_link"
                                         exact>
                                    {isFullWidth ? t('menu_item_anonymous') :
                                        <Tooltip placement="right" title={t('menu_item_anonymous')}>
                                            <AnonymousMenuIcon/>
                                        </Tooltip>
                                    }
                                </NavLink>
                        </Accordion.Body>
                    </Accordion.Item>}
                    {hasPermission(ROLE_ITEMS.ROLES_READ) &&
                        <Accordion.Item eventKey="3" className={`sub_menu ${!isFullWidth ? 'small-menu' : ''}`}>
                            <div
                                className={`menu_item ${location.pathname.includes('roles') ? 'active_menu' : ''} ${!isFullWidth ? 'small-menu' : ''}`}>
                                <NavLink
                                    to={`/dashboard/roles`}
                                    className="menu_link"
                                    activeClassName="active_link"
                                    exact>
                                    {isFullWidth ? <>
                                            <RolesIcon className="roles-icon"/>
                                            {isFullWidth && <p>{t('menu_item_roles')}</p>}
                                        </> :
                                        <Tooltip placement="right" title={t('menu_item_roles')}>
                                            <RolesIcon className="roles-icon"/>
                                        </Tooltip>
                                    }
                                </NavLink>
                                <ShevronTopIcon
                                    className={`menu_item--shevron-default `}
                                    // onClick={decoratedOnClick}
                                />
                                {/*<CustomToggle eventKey="3"/>*/}
                            </div>
                        </Accordion.Item>}
                </Accordion>
            </div>
            <div className="bottom-part">
                <DropdownMenu button={
                    <div className={`menu__user-content`}>
                        <img src={generateMediaUrl(userAvatar)} alt=""/>
                        {isFullWidth && <div className="inner">
                            <div className="name">
                                {getAuthorName(userProfile?.attached_person?.id ? userProfile?.attached_person : userById, null, i18n.language)}
                            </div>
                            <div className="company">
                                <BuildingsIcon/>
                                <span>{userProfile?.company?.name}</span>
                            </div>
                            {!!userProfile?.roles?.length &&
                                <div className="user">{userProfile?.roles?.[0]?.name}</div>}
                        </div>}
                    </div>
                }
                              className={`${!isFullWidth ? 'small-menu' : ''}`}
                              placement={'top'}
                              closeAfterToggle={true}
                              contentWidth={'190px'}>
                    <div className="menu-item" onClick={() => {
                        history.push(ROUTERS_MAP.PROFILE);
                    }}>{t('profile_profile')}
                    </div>
                    <div className="menu-item log-out" onClick={logOut}>
                        {t('profile_log_out')}
                    </div>
                </DropdownMenu>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'isMobile',
        'userById',
        'personById',
        'userProfile',
        'isFullWidth',
        'userRoles',
        'userAvatar',
    ])
};

const mapDispatchToProps = {
    LogOut,
    GetUserById,
    GetUserProfile,
    SetIsFullWidth,
    GetPersonById,
    ClearProfile,
    GetOutlookAuth,
    SetUserAvatar
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
