import React, {useCallback, useState} from 'react';
import './SourcesBlock.scss';
import {CopyIcon, PlusIcon, ShevronTopIcon} from "../../../../image";
import {Tooltip} from "antd";
import {copyToClipboard} from "../../../../utils/helperFunctions";
import {AddProtocol, GetProtocol} from "../../../../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";

const SourcesBlock = (props) => {
    const {item,videoInfo, copyMessage,listRef, messages,videoId} = props;
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const toggleIsOpen = useCallback(()=>setIsOpen(prevState => !prevState),[])
    const dispatch = useDispatch();
    const userInfo = useSelector(state => state.profile.userProfile);
    const {t} = useTranslation();
    const getTiles = (sub) => {
        return (sub?.sub_sources || []).map((el) => el.charAt(0).toUpperCase() + el.slice(1)).join(',')
    }
    const checkVideoPage = (location.pathname || '').includes('/dashboard/videos/video');
    const protocolList = useSelector(state => state.protocol?.protocolList || [])
    const lastProtocolId = protocolList.at(-1)?.id;

    const addFavorites = async (value) => {
        try {
            await dispatch(AddProtocol({
                video_id: videoInfo?.id,
                text: value,
                person_id: userInfo?.attached_person?.id,
                insert_after_protocol_block_id: lastProtocolId,

            }));
            await dispatch(GetProtocol(videoInfo?.id));
        } catch (e) {
            console.error(e)
        }
    }

    function removePrefix(url) {
        const prefix = "https://develop.secretopus.ai/";
        if (url.startsWith(prefix)) {
            return url.slice(prefix.length);
        }
        return url; // Если строка не начинается с префикса, возвращаем её без изменений
    }

    const onHandleClickSources = () => {
        toggleIsOpen();
        if(listRef.current && item?.id === messages.length - 1) {
            setTimeout(() => {
                listRef.current.scrollTop = (listRef.current.scrollHeight);
            }, 0);
        }
    }

    return (
        <div className="sources">
            <div className="sources__panel">
                {item?.sources?.length > 0 && item?.isBot ?
                <div className={`sources__panel__btn ${isOpen ? 'active' : ''}`} onClick={onHandleClickSources}>
                    <span>{t('sources')}</span>
                    <ShevronTopIcon/>
                </div>
                : null}
                <div className="sources__panel__actions">
                    <div className="sources__panel__actions-icons">
                        <Tooltip placement="top" title={'Copy'}>
                            <CopyIcon
                                onClick={() => copyMessage(item)}
                                className="sources__panel__actions-icons--copy"
                            />
                        </Tooltip>
                        {item?.isBot && checkVideoPage ?
                            <Tooltip placement="top" title={'Add to favorites'}>
                                <PlusIcon
                                    onClick={() => addFavorites(item.text)}
                                    className="sources__panel__actions-icons--plus"
                                />
                            </Tooltip>
                            : null}
                    </div>
                </div>
            </div>
            {isOpen ?
                <div className="sources__list">
                    {(item?.sources || []).map((item)=>{
                        if(item.type === 'video'){
                            return (
                                <span className="sources__list--item">
                                    <span className="sources__list--item-id">{item.id}.</span>
                                    <a
                                        className="sources__list--item-link"
                                        href={item?.link} target="__blank">
                                        <span>{item.title}</span>
                                    </a>
                                    {item?.created_at ?
                                        <span className="sources__list--item-subs">
                                            { dayjs(item?.created_at).format('DD.MM.YYYY')}
                                        </span>
                                    :null}
                                    <span className="sources__list--item-subs">
                                        {item?.sub_sources.length ? `(${getTiles(item)})` : ''}
                                    </span>
                                </span>
                            )
                        }
                    })}

                </div>
                : null}

        </div>
    );
};

export default SourcesBlock;