import React, {useEffect, useState} from 'react';
import './ChannelsPage.scss'
import {useDispatch, useSelector} from "react-redux";
import {GetChannels, GetVideosService} from "../../../redux/actions";
import LayoutContainer from "../../../modules/Layout/Layout";
import {PlusNewIcon} from "../../../image";
import ChannelsList from "./components/ChannelsList/ChannelsList";
import {hasPermission} from "../../../utils/helperFunctions";
import {Loader} from "../../../shared/features/Loader";
import {useTranslation} from "react-i18next";
import ChannelForm from "../../../modules/Channel/ChannelForm/ChannelForm";
import {ROLE_ITEMS} from "../../../utils/constants";

const ChannelsPage = (props) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const isFullWidth = useSelector(state => state.utils.isFullWidth);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedChannelId, setSelectedChannel] = useState(null);

    const getChannels = async () => {
        try {
            setIsLoading(true);
            const channels = await dispatch(GetChannels());
            setSelectedChannel(channels.filter((item)=>!item?.is_private)?.[0]?.id)
            await dispatch(GetVideosService({
                limit: 1000,
                channel_ids: (channels || []).map((item) => item?.id)
            }));
            setIsLoading(false);
        }catch (e) {
            setIsLoading(false);
            console.error(e)
        }
    }

    useEffect(()=>{
        getChannels()
    },[]);

    return (
        <LayoutContainer>
            <div className={`main-container channels-wrap ${isFullWidth ? '' : 'full'}`}>
                <div className="channels-block">
                    <div className="channels-block__header">
                        <span className="channels-block__header--title">{t('menu_item_channels')}</span>
                        {hasPermission(ROLE_ITEMS.CHANNELS_CREATE) ?
                            <button className="channels-block__header--btn" onClick={()=>setSelectedChannel(null)}>
                                <PlusNewIcon/>
                                <span>{t('create_channel')}</span>
                            </button>
                            :null}
                    </div>
                    <div className="channels-block__wrap">
                        <ChannelsList
                            selectedChannelId={selectedChannelId}
                            selectedChannel={setSelectedChannel}
                        />
                        {isLoading ?
                            <div className="channels-block__loader">
                                <Loader size={100}/>
                            </div>
                            :
                            <ChannelForm channelId={selectedChannelId}/>
                        }
                    </div>
                </div>
            </div>
        </LayoutContainer>
    );
};

export default ChannelsPage;