import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getOptionsSortedSelect} from "../../helpers";
import {useTranslation} from "react-i18next";
import {CheckBox} from "../../../CheckBox";
import {ArrowLeftIcon, SortIcon} from "../../../../image";
import {Popover} from "antd";
import {UpdateSortedParamsAction} from "../../../../redux/actions";
import './SortedSelect.scss';
import {getValueFilters} from "../../../Filters/helpers";


const SortedSelect = ({pageType, onGetVideos}) => {
	const {i18n, t} = useTranslation();
	const sortValue = useSelector(state => state.filtersOrSort?.[pageType].sort?.select);
	const sortItemsArr = getOptionsSortedSelect(i18n.language);
	const [open, setOpen] = useState(false);
	const dispatch = useDispatch();
	const [selectSort, setSelectSort] = useState();
	const allFilters = useSelector(state => state.filtersOrSort);
	const allFiltersValue = getValueFilters(allFilters, pageType);

	function handleOpenChange(newOpen) {
		setOpen(newOpen);
	}

	const getLabel = () => {
		if(sortValue) {
			return sortItemsArr.find((item) => item?.value === sortValue)?.label;
		}
		return sortItemsArr[0].label
	}

	const onSelectItem = async (itemValue) => {
		setSelectSort(itemValue);
		await dispatch(UpdateSortedParamsAction({
			pageType,
			itemSortType: 'select',
			itemValue
		}))
		setOpen(false)
		await onGetVideos({
			...allFiltersValue,
			sort_by: itemValue
		});
	}

	useEffect(() => {
		if(sortValue !== selectSort) {
			setSelectSort(null)
		}
	}, [sortValue]);

	return (
		<Popover
			arrow={false}
			open={open}
			trigger='click'
			placement="bottomLeft"
			overlayClassName='sort-select'
			onOpenChange={handleOpenChange}
			content={
				<div className="sort-select__container">
					<div className="sort-select__list">
						{sortItemsArr.map((sort) => (
							<div
								key={sort.id}
								className="filters-all__list--item">
								<CheckBox
									type={'radio'}
									checked={sortValue === sort.value}
									onChange={() => {
										onSelectItem(sort.value)
									}}
									label={sort.label}
								/>
							</div>
						))}
					</div>
				</div>
			}
		>
			<div className={`sort-select__label ${selectSort ? 'selected' : ''}`}>
				<div className={`sort-select__label_wrap`}>
					<SortIcon/>
					<span className="sort-select__label--text">{getLabel()}</span>
					<ArrowLeftIcon className={`sort-select__label--arrow ${open ? 'open' : ''}`}/>
				</div>
			</div>
		</Popover>
	);
};

export default SortedSelect;