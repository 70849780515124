// Import packages
import React, {useState} from 'react';
// Import assets
import './list-item.scss';
import {DeletingIcon, EditIcon, EditingIcon, EllipsisIcon, EnterIcon, VideoIcon} from "../../../../../../image";

// ImportComponents
import DropdownMenu from "../../../../../../shared/features/dropdownMenu";

// Import Utils
import {generateMediaUrl} from "../../../../../../utils/generateMediaUrl";
import {history} from "../../../../../../utils/history";
import {getAuthorName, hasPermission} from "../../../../../../utils/helperFunctions";
import {useTranslation} from "react-i18next";
import {Popover} from "antd";
import {useSelector} from "react-redux";
import {ROUTERS_MAP} from "../../../../../../utils/routes";
import UserDeleteModal from "../../../../../../modules/UserInfo/components/UserDeleteModal/UserDeleteModal";
import {ROLE_ITEMS} from "../../../../../../utils/constants";

function ListItem({data, openModal, setCurrentUser, rolesList, index, onDeleteUser}) {
    const {t, i18n} = useTranslation();
    const userProfile = useSelector(state => state.profile.userProfile);
    const [isVisibleDeleteAccountModal, setVisibleDeleteAccountModal] = useState(false);

    function toggleUpdate() {
        if(userProfile?.attached_person?.id === data?.person_id || userProfile?.id === data?.user_id){
            history.push(ROUTERS_MAP.PROFILE);
        }else{
            setCurrentUser(data)
            openModal()
        }
    }
    const onConfirmDeleteProfileButton = () => {
        setVisibleDeleteAccountModal(false)
        onDeleteUser(data);
    }
    function navigateTo() {
        !!data?.videos_count && history.push({
            pathname: `/dashboard/videos/userVideos/${data.person_id ? data.person_id : data.user_id}`,
            state: {
                personId: data.person_id ? data.person_id : data.user_id,
                routs: [
                    {
                        path: '/dashboard/users/persons',
                        name: t('user_page_title')
                    },
                    {
                        id: data.person_id ? data.person_id : data.user_id,
                        name: getAuthorName(data, null, i18n.language),
                    },
                    {
                        path: `/dashboard/videos/userVideos/${data.person_id ? data.person_id : data.user_id}`,
                        name: 'Видео с ' + getAuthorName(data, null, i18n.language),
                        active: true
                    }
                ]
            }
        })
    }
    const isCheckYou = userProfile?.id === data?.user_id;
    const tagColors = ['#4F40F7', '#2877D4', '#F7404B', '#CB43C6', '#CB7443']
    const role = rolesList?.find(i => i.id === data?.role_id)

    return (
        <div className={`list-item persons ${isCheckYou ? 'active' : ''}`}>
            <div className="list-item__content">
                <div className="user_info item_col">
                    <img
                        src={generateMediaUrl(data?.avatar)}
                        alt=""
                        className="user_info__img"
                    />


                    <div className="user_info__content">
                        <div className="name" onClick={toggleUpdate}>{data?.first_name}&nbsp;
                            {data?.last_name ? data?.last_name : ''}
                        </div>
                        {isCheckYou ?
                          <div className="email" style={{color: "#1abb78"}}>({t('you')})</div>
                          : <div className="email">{data?.login || 'user@gmail.com'}</div>
                        }
                    </div>
                </div>

                <div className="item_col">
                    {data?.tags?.length ? <DropdownMenu
                        button={<div className={'person_tag_wrap'}>
                            <div className="person_tag"
                                 style={{
                                     borderColor: tagColors[0],
                                     color: tagColors[0]
                                 }}>
                                {data?.tags?.[0]?.name}
                            </div>
                            {data?.tags?.length > 1 && <span className="tags-count">
                            +{data?.tags?.length - 1}
                        </span>}
                        </div>}
                        closeAfterToggle={false}
                        fullWidth={false}>
                        <div className="tags-list">
                            {
                                data?.tags?.length && data?.tags?.map((item, index) => {
                                    return <div key={`${item?.id}_${index}`}
                                                className="tag-item person_tag"
                                                style={{
                                                    borderColor: tagColors[index],
                                                    color: tagColors[index]
                                                }}>{item?.name}</div>
                                })
                            }
                        </div>
                    </DropdownMenu> : '-'}
                </div>

                <div className="item_col">
                    {role?.id ? <div className="role" style={{
                        backgroundColor: tagColors?.slice(1)[index >= 5 ? index % 5 : index] + '14',
                        color: tagColors?.slice(1)[index >= 5 ? index % 5 : index],
                    }}>{role?.name}</div> : '-'}
                </div>

                <div className="item_col light">
                    {data?.organization || '-'}
                </div>

                <div className="item_col light">
                    {data?.position || '-'}
                </div>
            </div>
            <div className="list-item__actions" onClick={(e) => e.stopPropagation()}>
                <div className="video_count item_col" onClick={navigateTo}>
                    <VideoIcon/> {data?.videos_count}
                </div>
                {isCheckYou ?
                  <EnterIcon
                    className={"list-item--enter"}
                    onClick={() => {
                        history.push(ROUTERS_MAP.PROFILE);
                    }}
                  />
                  :
                  <>
                  {(hasPermission(ROLE_ITEMS.FOREIGN_USERS_PROFILE_PERSONAL_EDIT)
                      || hasPermission(ROLE_ITEMS.FOREIGN_USERS_PROFILE_PARTICIPATION_EDIT)
                      || hasPermission(ROLE_ITEMS.FOREIGN_USERS_PROFILE_USER_SETTINGS_READ)
                    || hasPermission(ROLE_ITEMS.FOREIGN_USERS_DELETE)
                    ) ?
                <Popover
                    arrow={false}
                    trigger='click'
                    placement="rightTop"
                    overlayClassName='popover_edit-menu'
                    content={
                        <>
                        {(hasPermission(ROLE_ITEMS.FOREIGN_USERS_PROFILE_PERSONAL_EDIT)
                          || hasPermission(ROLE_ITEMS.FOREIGN_USERS_PROFILE_PARTICIPATION_EDIT)
                          || hasPermission(ROLE_ITEMS.FOREIGN_USERS_PROFILE_USER_SETTINGS_READ)) ?
                            <div
                                className={'popover_edit-menu--item'}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleUpdate()
                                }}
                            >
                                <EditIcon className={''}/> {t('button_edit')}
                            </div>
                          : null}
                            {hasPermission(ROLE_ITEMS.FOREIGN_USERS_DELETE) ?
                                <div
                                    className={'popover_edit-menu--item delete'}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setVisibleDeleteAccountModal(true)
                                    }}
                                >
                                    <DeletingIcon className={''}/> {t('button_delete')}
                                </div>
                              : null}
                        </>
                    }
                >
                    <div className="popover_edit-menu--settings">
                        <EllipsisIcon/>
                    </div>
                </Popover>
                    :
                    <EnterIcon
                      className={"list-item--enter"}
                      onClick={(e) => {
                          e.stopPropagation();
                          toggleUpdate()
                      }}
                    />
                  }
                </>
                }
            </div>
            {isVisibleDeleteAccountModal &&
              <UserDeleteModal
                show={isVisibleDeleteAccountModal}
                onClose={() => setVisibleDeleteAccountModal(false)}
                onConfirm={onConfirmDeleteProfileButton}
                isDeleteAccount={true}
              />}
        </div>
    );
}

export default ListItem;
