import React from 'react';
import {Route, useParams} from "react-router-dom";
import {ROUTERS_MAP} from "../../utils/routes";
import ManageVideo from "./ManageVideo/ManageVideo";
import {ChannelsPage} from "./ChannelsPage";
import ChatBlock from "../../shared/chat/ChatBlock";
import {useSelector} from "react-redux";

const ManageDataPage = () => {
  const isAuth = useSelector(state => Boolean(state.auth?.token));

    return (
        <div>
            <Route exact path={ROUTERS_MAP.MANAGE_DATA} component={ManageVideo}/>
            <Route path={ROUTERS_MAP.CHANNELS} component={ChannelsPage}/>
            {isAuth ? <ChatBlock/> : null}
        </div>
    );
};

export default ManageDataPage;