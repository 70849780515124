// Import packages
import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";

// Import assets
import './new.scss';

// Import Utils
import {getPropsFromState} from "../../../../../redux/mapStateToProps";
import {
    GetNewPersons,
    ClearUsers,
    ChangeHeaderTitle,
    ClearPersons,
    DeletePersonService
} from "../../../../../redux/actions";

// Import Components
import ListItem from "./listItem/ListItem";
import CustomPagination from "../../../../../shared/features/Pagination";
import UserInfoModal from "../modals/userInfoModal/UserInfoModal";
import {useTranslation} from "react-i18next";
import {Toast} from "react-bootstrap";
import {ErrorIcon, SuccessIcon} from "../../../../../image";

const listLength = 10

function New(props) {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false)
    const [currentUser, setCurrentUser] = useState(null)
    const [activePage, setActivePage] = useState(1);
    const dispatch = useDispatch();
    const [toastInfo, setToastInfo] = useState({isShow: false, type: 'danger'});
    const newPersonsList = useSelector(state => state.users.usersPersonsList);

    useEffect(() => {
        props.ChangeHeaderTitle(t('members_title'))

        return () => {
            props.ClearPersons()
        }
    }, [])

    useEffect(() => {
        !showModal && props.GetNewPersons({
            status: 'new'
        })
    }, [])

    function openModal() {
        setShowModal(true)
    }

    function closeModal() {
        setShowModal(false)
    }

    const changePage = (number) => {
        setActivePage(number)
        props.GetNewPersons({
            status: 'new',
            limit: listLength,
            offset: listLength * (number - 1)
        })
    };

    async function deletePerson(data) {
        try{
            await DeletePersonService(data?.person_id);
            setToastInfo({
                isShow: true,
                type: 'success',
                text:  t("delete_participant_success_text")
            });
            await dispatch(GetNewPersons({
                status: 'new',
                limit: 10,
                offset: newPersonsList?.offset,
            }));
        }catch (error) {
            console.error(error);
            setToastInfo({
                isShow: true,
                type: 'danger',
                text:  t("delete_participant_error_text")
            });
        }
    }

    return (
        <div className="members">
            <div className="members__wrap">
            <div className="page-title" style={{padding: '20px 0 0'}}>{t('members_title')}</div>

            <div className="members__list-header">
                <div className="members__list-header__content">
                    <div className="item_col">{t('table_name')}</div>
                    <div className="item_col">{'ID'}</div>
                    <div className="item_col">{t('table_data')}</div>
                    <div className="video_count item_col">
                        {t('table_video')}
                    </div>
                </div>
                <div className="members__list-header__actions">
                    {/*<div className="video_count item_col">*/}
                    {/*    {t('table_video')}*/}
                    {/*</div>*/}
                </div>
            </div>

                {
                    props.newPersonsList?.results?.map(item => {
                        const data = {
                            ...item,
                            person_id: item.id
                        }
                        delete data.id
                        return <ListItem
                            key={data?.person_id}
                            data={data}
                            openModal={openModal}
                            setCurrentUser={setCurrentUser}
                            onDeleteUser={deletePerson}
                        />
                    })
                }
            {props.newPersonsList?.count > 10 && <CustomPagination count={props.newPersonsList?.count}
                                                                   activePage={activePage}
                                                                   listLength={listLength}
                                                                   changePage={changePage}/>}
            {currentUser && showModal && <UserInfoModal show={showModal}
                                           closeModal={closeModal}
                                           user={currentUser}/>}
                <Toast
                    className='upload_video_answer'
                    onClose={() => setToastInfo({isShow: false, type: toastInfo.type})}
                    show={toastInfo.isShow}
                    delay={3000}
                    autohide
                >
                    <div className="toasblock">
                        {toastInfo.type === 'danger' ? <ErrorIcon/> : <SuccessIcon/>}
                        <div className="toasblock__wrap">
						                <span
                                            className="toasblock__wrap--title">{toastInfo.type === 'danger' ? t('toast_error_title') : t('toast_success_title')}</span>
                            <span className="toasblock__wrap--text">{toastInfo?.text || ''}</span>
                        </div>
                    </div>
                </Toast>
            </div>
        </div>
    );
}


const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'newPersonsList',
    ])
};

const mapDispatchToProps = {
    GetNewPersons, ClearUsers, ChangeHeaderTitle, ClearPersons
};

export default connect(mapStateToProps, mapDispatchToProps)(New);
